body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.section {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: red;
  height: 100vh;
}

.bottomSection {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: red;
  height: 100vh;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.bottomSection.show {
  opacity: 1;
  transform: translateY(0);
}

.section {
  position: relative;
}

.text-section {
  flex: 1;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: white;
  flex-direction: column;
  text-align: center;
  margin: 0;

}

.Footer {
  background-color: #222;
  color: white;
  text-align: center;
  padding: 20px;
  font-size: 1rem;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.footer-links a {
  color: #61dafb;
  text-decoration: none;
  font-weight: bold;
}

.footer-links a:hover {
  text-decoration: underline;
}

.email,
.token-contract {
  margin: 5px 0;
  font-size: 0.9rem;
}

.email a {
  color: #61dafb;
  text-decoration: none;
}

.email a:hover {
  text-decoration: underline;
}

.token-contract span {
  font-weight: bold;
  color: #ddd;
}

.swiper-container {
  position: relative;
}

.floating-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #d00404;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border: 1px solid white;
  z-index: 10;
  /* Make sure it appears above the slides */
  text-decoration: none;
  display: flex;
  align-items: center;
}

.floating-button .icon {
  margin-left: 5px;
  font-size: 1.5rem;
}

/* Social Icons */
.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 10px;
  justify-content: center;
}

.social-icon {
  font-size: 2rem;
  color: #61dafb;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: white;
}

.text-section h4 {
  margin: 0;
}

.image-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  gap: 20%;

}

.image-links h4 {
  color: white;
  font-size: large;
  font-weight: bold;
}

.link-container {
  text-align: center;
}

.circle-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* Makes the image circular */
  object-fit: cover;
  cursor: pointer;
  /* Changes the cursor to a pointer when hovered */
}

.image-name {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}



.text-section h1 {
  margin: 0;
}

.text-section h3 {
  margin: 0;
}

.video-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icon {
  font-size: 1.5rem;
  margin-right: 10px;
  vertical-align: middle;
  color: white;
}

.third-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: red;
  height: 100vh;
  width: 100vw;
  background-color: #b80000;
}


.third-section img {

  height: 100vh;

}


.tokenomics-section {
  flex: 1;
  background-color: #b80000;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.text-section-third {

  color: white;
  text-align: center;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-size: 2rem;
}

.like-section {
  padding: 20px;
  display: flex;
}

.click-me-button {
  position: relative;
  font-weight: bold;
  background-color: transparent;
  color: #fff;
  font-size: 46px;
  cursor: pointer;
  padding: 10px;
  text-align: center;

}

.arrow-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0%;
  right: 20%;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
  text-align: center;


}

.moving-arrow {
  display: flex;
  align-items: center;
  animation: moveArrow 1s infinite alternate;
}

/* Animation for the arrow */
@keyframes moveArrow {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateY(10px);
    /* Adjust distance for the back and forth motion */
  }
}

.fourth-section {
  width: 100vw;
  height: 80vh;

}

.arrow-icon {
  transform: rotate(45deg);
  font-size: 118px;
  color: white;
  /* Add a border */
  border-radius: 5px;
  /* Round the corners slightly */
  padding: 10px;
  /* Add some padding around the icon */
  animation: borderColorShift 2s infinite;
}

/* Keyframes for border color animation */
@keyframes borderColorShift {
  0% {
    color: red;
  }

  50% {
    color: white;
  }

  100% {
    color: blue;
  }
}

.like-icon {

  font-size: 36px;
  color: black;

}

.like-count {
  margin: 0;
  font-weight: bold;
}

.image-section {

  width: 100vw;
  height: 80vh;
  display: flex;
  justify-self: center;
  overflow: hidden;
}



.image-wrapper {
  position: relative;
  width: 100vw;
}

.image {
  width: 100%;
  height: auto;
}

.overlay-button {
  position: absolute;
  font-weight: bold;
  top: 50%;
  left: 15%;
  transform: translate(-50%, -50%);
  background-color: #d00404;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid white;
}

.overlay-button:hover {
  opacity: 1;
}

.tokenomics-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  transition: color 0.3s ease;
}

.tokenomics-block {
  align-items: center;
  display: flex;
  background-color: #3b2f2f;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 5px;
  transition: transform 0.3s ease;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

.tokenomics-block:hover {
  transform: translateY(-10px);
}

.tokenomics-block h3 {
  margin: 0;
  font-size: 1.5rem;
}



.tokenomics-block p {
  margin: 5px 0 0;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .section {
    flex-direction: column;
  }

  .video-section {
    height: 100vh;
  }

  video {
    width: 100vw;
    height: 100vh;
  }
}

@media (max-width: 768px) {

  /* General Styles */
  body,
  html {
    overflow-x: hidden;
  }

  .container {
    height: auto;
  }

  .text-section {
    width: 100%;
    position: absolute;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  }

  .section,
  .bottomSection,
  .third-section,
  .fourth-section {
    flex-direction: column;
    height: auto;
    /* Adjust height for mobile */
  }

  /* Text Sections */
  .text-section,
  .text-section-third {
    font-size: 2rem;
    /* Reduce font size */
    padding: 10px;
    text-align: center;
  }

  .text-section h4 {
    font-size: 1rem;
  }

  .text-section h1 {
    font-size: 2.5rem;
    /* Reduce font size */
  }

  .text-section h3 {
    font-size: 1.2rem;
    /* Reduce font size */
  }

  /* Video Sections */
  .video-section {
    height: 50vh;
    /* Reduce video height */
  }

  video {
    width: 100%;
    height: 100%;
  }

  /* Tokenomics Section */
  .tokenomics-section {
    padding: 10px;
    font-size: 1rem;
    /* Reduce font size */
    text-align: center;
  }

  .tokenomics-title {
    font-size: 1.5rem;
    /* Reduce font size */
  }

  .tokenomics-block {
    flex-direction: row;
    /* Stack items vertically */
    padding: 10px;
    font-size: 1rem;
    /* Reduce font size */
    margin-bottom: 10px;
  }

  .tokenomics-block h3 {
    font-size: 1.2rem;
    /* Reduce font size */
  }

  .tokenomics-block p {
    font-size: 1rem;
    /* Reduce font size */
  }

  /* Like Section */
  .like-section {
    padding: 10px;
    justify-content: center;
  }

  .like-button {
    font-size: 1.5rem;
    /* Reduce button size */
  }

  .like-icon {
    font-size: 2rem;
    /* Reduce icon size */
  }

  .like-count {
    font-size: 1.2rem;
    /* Reduce font size */
    margin-left: 5px;
  }

  /* Click Me Button and Arrow */
  .click-me-button {
    font-size: 1.2rem;
    /* Reduce font size */
  }

  .arrow-container {
    bottom: 10px;
    /* Adjust position */
    right: 10px;
    /* Adjust position */
    padding: 5px;
  }

  .arrow-icon {
    font-size: 2.5rem;
    /* Reduce icon size */
  }

  /* Overlay Button */
  .overlay-button {
    font-size: 1rem;
    /* Reduce font size */
    padding: 5px 10px;
  }

  /* Image Section */
  .image-section {
    height: auto;
    /* Adjust height */
    padding: 10px;
  }


  .image {
    width: 100%;
    height: auto;
  }

  /* Fourth Section */
  .fourth-section {
    height: 60vh;
    /* Adjust height */
  }

  .bottomSection .video-section {
    display: none;
  }

  .text-section-third {
    font-size: large;
    flex-direction: column;
  }

  .overlay-button {
    left: 200px;
  }

  .image-section {
    flex-direction: column;
  }
}

/* Hide carousel on larger screens */
@media (min-width: 769px) {
  .mobile-carousel {
    display: none;
  }
}

/* Display carousel on smaller screens */
@media (max-width: 768px) {
  .image-section {
    display: block;
  }

  .mobile-carousel {
    width: 100%;
    height: auto;
  }

  .image {
    width: 100%;
    height: auto;
  }

  .image-links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;


  }

  .link-container h4 {
    color: white;
    font-weight: bold;

  }


  .circle-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* Makes the image circular */
    object-fit: cover;
    cursor: pointer;
    /* Changes the cursor to a pointer when hovered */
  }


}